<template>
  <div class="content">
    <PageHeader title="RCS Dashboard" />
    <div class="page-content container-fluid">
      <div class="row" v-if="fetched && this.client.active">
        <div class="col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-header">
              <span class="m-t-5 d-inline-block">{{$tc('generic-str.message', 2)}}</span>
              <ul
                class="nav nav-pills nav-pills-primary float-right"
                id="pills-demo-sales"
                role="tablist"
              >
                <li class="nav-item">
                  <select v-model="messageDays" class="form-control">
                    <option value="7">{{$t('sms.dashboard.messages.days.7')}}</option>
                    <option value="30">{{$t('sms.dashboard.messages.days.30')}}</option>
                  </select>
                </li>
              </ul>
            </div>
            <div class="card-body block-el">
              <messages-chart :days="messageDays" />
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="fetched && this.client.active">
        <div class="col-lg-12">
          <recent-card />
        </div>
      </div>
      <div class="card-body block-el p-0" v-if="!fetched">
        <div class="responsive-table">
          <div class="static qt-block-ui" style="padding: 120px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import MessagesChart from '@/components/rcs/MessagesChart.vue';
import RecentCard from '@/components/rcs/RecentCard.vue';
import RCSService from '@/services/rcs.service';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    MessagesChart,
    RecentCard,
  },
  created() {
    this.fetchClient();
  },
  data() {
    return {
      fetched: false,
      client: {},
      messageDays: 7,
      carrierDays: 7,
    };
  },
  methods: {
    fetchClient() {
      Promise.all([RCSService.getAgent()])
        .then(
          (responses) => {
            this.client = responses[0].data;
            this.fetched = true;
            if (!this.client.active) {
              this.$router.push('/403');
            }
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
  },
};
</script>
