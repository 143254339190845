<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <span class="col-lg-6 col-md-6 col-sm-6 m-t-5 d-inline-block"
          >{{$t('sms.dashboard.lbl-recent-msg')}}</span
        >
        <div class="col-lg-6 col-md-6 col-sm-6 text-right text-left-m">
          <router-link class="btn btn-primary" to="/rcs/history"
            >{{$t('sms.dashboard.lbl-show-history')}}</router-link
          >
        </div>
      </div>
    </div>
    <div class="card-body block-el">
      <div v-if="loaded" class="responsive-table">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{$t('generic-str.date')}}</th>
              <th scope="col">{{$t('generic-str.type')}}</th>
              <th scope="col">{{$t('generic-str.lbl-recipient')}}</th>
              <th scope="col">{{$tc('generic-str.message', 1)}}</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              v-for="message in messages"
              :key="message.id"
              :to="`rcs/history/${message.id}`"
              tag="tr"
            >
              <td data-label="Data">
                <div v-if="message.sent_at">
                  <router-link :to="`rcs/history/${message.id}`">
                    {{ message.sent_at | formatDate24}}
                  </router-link>
                </div>
                <div v-else-if="message.created_at">
                  <router-link :to="`rcs/history/${message.id}`">
                    {{ message.created_at | formatDate24}}
                  </router-link>
                </div>
                <div v-else-if="message.createdAt">
                  <router-link :to="`rcs/history/${message.id}`">
                    {{ message.createdAt | formatDate24}}
                  </router-link>
                </div>
              </td>
              <td data-label="Tipo">
                <template v-if="message.direction == 'outbound'"
                  >{{$t('generic-str.send-2')}}</template
                >
                <template v-else-if="message.direction == 'inbound'"
                  >{{$tc('generic-str.status.lbl-response', 1)}}</template
                >
              </td>
              <td data-label="Destinatário">{{ message.to }}</td>
              <td
                data-label="Mensagem"
                class="max-200"
              >
                <router-link :to="`rcs/history/${message.id}`">
                    {{$t('generic-str.view-msg')}}
                  </router-link>
              </td>
              <td data-label="Status">
                <span
                  v-if="message.status == 'accepted'"
                  class="badge badge-light"
                  >{{$tc('generic-str.status.lbl-pending', 1)}}</span
                >
                <span
                  v-else-if="message.status == 'queued'"
                  class="badge badge-secondary"
                  >{{$t('generic-str.status.lbl-queue')}}</span
                >
                <span
                  v-else-if="message.status == 'sending'"
                  class="badge badge-accent"
                  >{{$t('generic-str.status.lbl-sending')}}</span
                >
                <span
                  v-else-if="message.status == 'sent'"
                  class="badge badge-info"
                  >{{$t('generic-str.status.lbl-sent')}}</span
                >
                <span
                  v-else-if="message.status == 'read'"
                  class="badge badge-info"
                  >{{$t('generic-str.status.lbl-readed')}}</span
                >
                <span
                  v-else-if="message.status == 'failed'"
                  class="badge badge-danger"
                  >{{$t('generic-str.status.lbl-failure')}}</span
                >
                <span
                  v-else-if="message.status == 'delivered'"
                  class="badge badge-success"
                  >{{$t('generic-str.status.lbl-delivered')}}</span
                >
                <span
                  v-else-if="message.status == 'undelivered'"
                  class="badge badge-dark"
                  >{{$t('generic-str.status.lbl-unavailable')}}</span
                >
                <span
                  v-else-if="message.status == 'received'"
                  class="badge badge-success"
                  >{{$tc('generic-str.status.lbl-response', 1)}}</span
                >
              </td>
            </router-link>
          </tbody>
        </table>
      </div>
      <div v-if="!loaded" class="qt-block-ui" />
      <div
        v-if="!messages.length && loaded"
        class="text-center table-placeholder"
      >
        <i class="fas fa-comment font-size-80"></i>
        <h5 class="card-title m-t-20">{{$t('wpp-component.none')}}</h5>
        <p>{{$t('wpp-component.first-msg')}}</p>
        <router-link
          to="/rcs/send"
          type="button"
          class="btn btn-success"
          >{{$t('wpp-component.new-msg')}}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import RCSService from '@/services/rcs.service';

export default {
  name: 'RecentCard',
  data() {
    return {
      loaded: false,
      messages: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      RCSService.filterMessages({
        limit: '10',
        channel: 'rcs',
      }).then(
        (messages) => {
          this.loaded = true;
          this.messages = messages;
          console.log(messages);
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.card-body {
  min-height: 300px;
}
tr {
  cursor: pointer;
  &:hover {
    background-color: #fafafa; /* #f0f6ff */
  }
}
</style>
