import { render, staticRenderFns } from "./RecentCard.vue?vue&type=template&id=15d74891&scoped=true&"
import script from "./RecentCard.vue?vue&type=script&lang=js&"
export * from "./RecentCard.vue?vue&type=script&lang=js&"
import style0 from "./RecentCard.vue?vue&type=style&index=0&id=15d74891&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d74891",
  null
  
)

export default component.exports